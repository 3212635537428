.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f8fafc;
  color: #1e293b;
}

.products-page-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.page-title {
  font-size: 2.5rem;
  color: #1e3a8a;
  text-align: center;
  margin-bottom: 1.5rem;
  font-weight: 700;
}

.section-title {
  font-size: 2rem;
  color: #1e3a8a;
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.product-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  padding: 0.5rem;
  max-width: 1200px;
  margin: 0 auto;
  flex-grow: 1;
}

.product-card {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  overflow: hidden;
}

.product-content {
  text-align: center;
}

.features-toggle {
  background: #2563eb;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin: 1rem 0;
}

.features-toggle:hover {
  background: #1d4ed8;
}

.features-section {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease, padding 0.5s ease;
  padding: 0;
  margin-top: 1rem;
}

.product-card.expanded .features-section {
  max-height: 2000px; /* Adjust based on content */
  padding-top: 2rem;
  border-top: 1px solid #e5e7eb;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
}

.feature-item {
  background: #f8fafc;
  padding: 1.25rem;
  border-radius: 8px;
  text-align: left;
}

.feature-title {
  color: #1e3a8a;
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.feature-description {
  color: #64748b;
  font-size: 0.95rem;
  line-height: 1.5;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .features-grid {
    grid-template-columns: 1fr;
  }

  .product-card {
    padding: 1.5rem;
  }

  .features-toggle {
    width: 100%;
    padding: 1rem;
  }

  .product-card.expanded {
    margin: 1rem -1rem;
    border-radius: 0;
  }
}

/* Animation for expanding/collapsing */
.product-card {
  scroll-margin-top: 2rem;
}

.product-card.expanded {
  scroll-behavior: smooth;
}

.product-icon {
  margin-bottom: 1rem;
}

.product-name {
  margin-bottom: 0.5rem;
}

.product-description {
  margin-bottom: 1rem;
}
